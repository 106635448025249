
.icon { 
    width: 30px;
    height: 30px;
}

/* PageNavbar */ 

.pageNavbar { 
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    height: 80px;
    padding-left: 1rem;
    padding-right: 1rem;
    z-index: 99;
}

#AWDLogoPageNavbar { 
    width: 50vw;
    max-width: 250px;
    max-height: 50px;
}

/* Navbar Right */ 

.pageNavbarRight { 
    display: flex;
    align-items: center;
    gap: 20px;
}

.pageNavbarRight button { 
    display: flex;
    align-items: center;
    gap: 10px;
    border: none;
    background-color: white;
}

.pageNavbarRight button { 
    font-size: 20px;
    font-weight: bold;
    color: #333333;
}

/* Navbar Right Angebot */ 

.offerRequestButton { 
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    width: 270px;
    height: 40px;
    margin-left: 16px;
    background-color: orange;
    border: none;
    text-decoration: none;
    border-radius: 5px;
    color: white;
}

@media screen and (max-width: 1050px) {

    .pageNavbarRight button p { 
        display: none;
    }

    /* Navbar Responsiv */ 
    
}

@media screen and (max-width: 700px) {
    #pageNavbarOfferRequestButton { 
        display: none;
    }
}
