
.angebotCardGrid { 
    margin: 30px 20px;
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    width: 100%;
    grid-template-areas:
    "angebotTitle"
    "angebotSubTitle"
    "angebotVariantImageGrid"
    "angebotTitleFormular"
    "angebotInputFormular"
    ;
}

.angebotVariantImageGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 10px;
    grid-template-areas:
        "angebotImageDetailMap vehicleImage angebotPhone"
        "angebotMail vehicleImage angebotImageMap"
    ;
}

@media screen and (max-width: 800px) {
    .angebotVariantImageGrid {
        grid-template-areas:
            "angebotImageDetailMap angebotPhone angebotPhone"
            "angebotMail angebotMail angebotImageMap"
        ;
    }
}

.angebotTitle { 
    grid-area: angebotTitle;
    font-size: 30px;
    font-weight: bold;
    color: #333333;
}

.angebotSubTitle { 
    grid-area: angebotSubTitle;
    font-size: 20px;
    font-weight: bold;
    color: gray;
}

.angebotTitleFormular { 
    grid-area: angebotTitleFormular;
    font-size: 30px;
    margin-top: 30px;
    font-weight: bold;
    color: #333333;
}