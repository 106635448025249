
/* Card Container */
.winterdienstSelectCardContainer { 
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 20px;
    grid-area: wdSelectCard;
}

/* Select Button Grid  */
.winterdienstSelectGrid { 
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 40px;
    align-items: center;
    text-align: center;
}

@media screen and (max-width: 800px) {
    .winterdienstSelectGrid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 40px 40px;
    }
}

/* Select Button */
.winterdienstSelectButton { 
    border: none;
    height: 100%;
    border-radius: 20px 20px 0px 0px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
}

/* Select Info Container */
.winterdienstSelectInfoContainer { 
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 10px;
    background-color: rgb(84, 171, 233);
    border-radius: 0px 0px 20px 20px;
    justify-items: center;
    align-items: center;
    grid-template-areas: 
     "selctInfoImage selctInfo selctInfo"
    ;
}

@media screen and (max-width: 800px) {
    .winterdienstSelectInfoContainer {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-gap: 20px;
        grid-template-areas: 
        "selctInfoImage"
        "selctInfo"
        ;
    }
}

/* Image Container */
.winterdienstSelectInfoImageContainer {
    display: flex; /* Flexbox verwenden */
    align-items: center; /* Vertikal zentrierte Ausrichtung */
    justify-content: center; /* Horizontal zentrierte Ausrichtung */
    height: 200px;
    overflow: hidden;
    grid-area: selctInfoImage;
    border-radius: 10px;
}

/* Image */
.winterdienstSelectImage {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.winterdienstSelectInfoTextContainer { 
    display: flex;
    flex-direction: column;
    color: white;
    grid-area: selctInfo;
    padding: 0px 30px;
    text-align: center;
    align-items: center;
    gap: 10px;
}

.winterdienstSelectInfoText { 
    color: white;
    font-weight: 500;
    font-size: 16px;
}

.winterdienstSelectInfoButtonContainer { 
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

@media screen and (max-width: 600px) {
    .winterdienstSelectInfoTextContainer { 
        text-align: left;
    }
}

