
.homeTopicCard { 
    width: 100%;
    max-width: 700px;
    border-radius: 24px;
    overflow: hidden;
    box-shadow: 5px 5px 5px lightgrey;
    background-color: #ffffff;
}

.homeTopicCardImageContainer { 
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
}

.homeTopicCardImage { 
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.homeTopicCardContent { 
    display: flex;
    flex-direction: column;
    margin: 16px;
    justify-content: space-between;
    flex-grow: 1;
}

.homeTopicCardTextContent { 
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.homeTopicTitle { 
    font-size: 35px;
    font-weight: bold;
    color: rgb(64, 64, 64);
}

.homeTopicCardSubTitleContainer { 
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.homeTopicSubTitle { 
    font-size: 20px;
    font-weight: bold;
    color: rgb(157, 157, 157);
    margin-right: 10px;
}

.homeTopicCardSpacer {
    height: 2px;
    background-color: rgb(226, 226, 226);
    margin-top: 2px;
    margin-bottom: 2px;
}

.homeTopicCardDescription { 
    font-size: 16px;
    font-weight: normal;
    color: rgb(64, 64, 64);
    margin: 10px;
    flex-grow: 1;
    min-height: 90px;
}

#homeTopicCardNavigationButton { 
    align-self: flex-end;
}

#homeTopicCardBeratung { 
    background-color: rgba(255, 159, 88, 0.3);
}

@media screen and (max-width: 800px) {
    .homeTopicTitle { 
        font-size: 30px;
    }

    .homeTopicSubTitle { 
        font-size: 15px;
    }
}