
.rechtContentGrid { 
    margin-top: 10px;
    display: grid;
    margin-top: 50px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 50px;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: 
     "rechtTitle rechtTitle"
     "rechtSubTitle rechtSubTitle"
     "gesetz1 gesetz2"
     "gesetz3 gesetz4"
    ;
}
@media screen and (max-width: 800px) {
    .rechtContentGrid {
        grid-template-columns: 1fr;
        grid-template-areas:
            "rechtTitle"
            "rechtSubTitle"
            "gesetz1"
            "gesetz2"
            "gesetz3"
            "gesetz4"
        ;
    }
}

.rechtTitle { 
    font-weight: bold;
    font-size: 40px;
    grid-area: rechtTitle;
}

.rechtSubTitle {
    font-weight: 600;
    font-size: 25px;
    color: gray;
    grid-area: rechtSubTitle;

}