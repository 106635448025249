.webLinkButton { 
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    width: 250px;
    height: 40px;
    margin-left: 16px;
    background-color: orange;
    border: none;
    text-decoration: none;
    border-radius: 5px;
    color: white;
}