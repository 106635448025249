.fuhrparkVehicleContainer { 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    background-color: white;
    padding: 10px;
    gap: 10px;
    box-shadow: 5px 5px 5px lightgrey;
    border-radius: 20px;
    grid-area: fuhrparkVehicleCard;
}

.fuhrparkVehicleTitle { 
    font-size: 30px;
    font-weight: bold;
}

.fuhrparkVehicleSubTitle { 
    font-size: 20px;
    font-weight: bold;
    color: gray;
}

.fuhrparkVehicleDescription { 
    text-align: center;
}

.fuhrparkVehicleCardBulletPointContainer { 
    display: flex;
    flex-direction: column;
    gap: 3px;
    align-items: left;
}

.fuhrParkBulletPointTextContainer { 
    display: flex;
    gap: 5px;
}

.fuhrparkVehicleCardBulletPointTitle { 
    font-weight: bold;
    text-align: right;
    font-size: 18px;
    min-width: 32px;
}

.fuhrparkVehicleCardBulletPointText { 
    font-weight: 400;
    text-align: left;
}

@media screen and (max-width: 800px) {
    .fuhrParkVehicleBulletPointTextContainer {
        display: flex;
        flex-direction: row;
        text-align: center;
        gap: 3px;
    }

    #fuhrparkDetailImage {
        max-height: 200px;
    }
}