
.winterdienstCardGrid { 
    /*
    
    gap: 20px;
    justify-items: center;
    margin: 24px;
    */
    margin: 30px 20px;
    display: grid;
    max-width: 1400px;
    height: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 20px;
    grid-template-areas: 
     "wdInfoCard wdInfoCard wdImageCard"
     "wdSelectCard wdSelectCard wdSelectCard"
     "wdRechtCard wdZipCodeCard wdZipCodeCard"
     "wdFahrzeugeCard wdFahrzeugeCard wdFahrzeugeCard"
     "wdReferenzenCard wdReklamationCard wdReklamationCard"
    ;
}

@media screen and (max-width: 1370px) {
    .winterdienstCardGrid {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
     "wdInfoCard wdImageCard"
     "wdSelectCard wdSelectCard"
     "wdRechtCard wdZipCodeCard"
     "wdFahrzeugeCard wdFahrzeugeCard"
     "wdReferenzenCard wdReklamationCard"
    ;
    }
}

@media screen and (max-width: 920px) {
    .winterdienstCardGrid {
    grid-template-columns: 1fr;
    grid-template-areas: 
     "wdInfoCard"
     "wdImageCard"
     "wdSelectCard" 
     "wdFahrzeugeCard"
     "wdZipCodeCard"
     "wdRechtCard"
     "wdReferenzenCard"
     "wdReklamationCard"
    ;
    }
}
.imageCardImage {
    width: 100%;
    height: 100%;
}