
.winterdienstInfoCardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 20px;
    padding: 20px;
    background-color: #ffffff; 
    box-shadow: 5px 5px 5px lightgrey;
    grid-area: wdInfoCard;
}

#AWDLogoWinterdienstInfoCard {
    width: 100%;
    max-width: 400px;
}

.winterdienstInfoCardText {
    margin-top: 10px;
}