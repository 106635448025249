.gruenanlagenCardGrid { 
    margin: 30px 20px;
    display: grid;
    max-width: 1400px;
    height: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 20px;
    grid-template-areas: 
     "gruenanlagenInfo gruenanlagenInfoImage"
     "gruenanlagenScrollview gruenanlagenScrollview"
     "gruenanlagenSpezial gruenanlagenSpezialImage"
     "gurenanlagenFeatureImage gurenanlagenFeature"
    ;
}

@media screen and (max-width: 1000px) {
    .gruenanlagenCardGrid {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-gap: 10px;
        grid-template-areas:
            "gruenanlagenInfo" 
            "gruenanlagenInfoImage"
            "gruenanlagenScrollview"
            "gruenanlagenSpezial" 
            "gruenanlagenSpezialImage"
            "gurenanlagenFeature"
            "gurenanlagenFeatureImage"
        ;
    }
}

#gruenanlagenInfoImage { 
    max-height: 300px;
}

#gruenanlagenSpezialImage { 
    max-height: 300px;
}

#gurenanlagenFeatureImage { 
    max-height: 300px;
}