
* { 
    font-family: "Avenir Next", sans-serif;
    padding: 0px;
    margin: 0px;
}

.background { 
    background-color: #f5f5f5;
}

.AWDPageContainer { 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

.AWDPageContent { 
    background-image: linear-gradient(
        rgba(245, 245, 245, 0.0),
        rgba(245, 245, 245, 0.3) 10px,
        rgba(245, 245, 245, 0.7) 20px,
        rgba(245, 245, 245, 0.9) 30px,
        #f5f5f5 40px);
    margin-top: 150px;
    padding-top: 30px ;
    border: none;
    border-radius: 3vw 3vw 0 0;
    display: flex;
    justify-content: center;
}


.navigationButton { 
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    width: 50%;
    height: 40px;
    background-color: orange;
    border: none;
    text-decoration: none;
    border-radius: 5px;
    color: white;
}

.AWDBasicPageContent { 
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-grow: 1;
    margin: 30px 20px 20px 20px;
}

.basicPageTitle { 
    font-weight: bold;
    font-size: 40px;
    grid-area: basicPageTitle;
    color: #333333;
    margin-bottom: 4px;
}

.basicPageSubTitle {
    font-weight: 600;
    font-size: 25px;
    color: gray;
    grid-area: basicPageSubTitle;
}

.basicPageText {
    font-size: 20px;
    color: #333333;
    grid-area: basicPageText;
}