
/* Container */ 
.angebotInputContainer { 
    grid-area: angebotInputFormular;
    background-color: rgb(84, 171, 233);
    border-radius: 20px;
}

/* Input Grid */ 
.angebotInputGrid { 
    display: grid;
    grid-gap: 10px;
    margin: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: 
        "adressGrid"
        "objectGrid"
        "objectDetailGridContainer"
        "angebotObjectMessageGrid"
        "angebotDatenschutzContainer"
        "sendMailButton"
    ;
}

.angebotInputGrid input { 
    height: 30px;
}

.angebotInputTitle { 
    font-size: 20px;
    font-weight: 600;
}

.angebotLongInput { 
}

/* Adress Grid */ 

.angebotAdressGrid { 
    grid-area: adressGrid;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 5px;
    grid-template-areas: 
    "angebotInputTitle angebotInputTitle"
    "senderName senderName"
    "senderStreet senderZipCode"
    "senderPhone senderMail"
    ;
}

/* Objekt Grid */ 
.angebotObjectGrid { 
    grid-area: objectGrid;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 5px;
    grid-template-areas: 
    "angebotInputTitle angebotInputTitle angebotInputTitle angebotInputTitle"
    "objectAdress objectAdress objectZipCode angebotExtraTitle"
    ;
}

.angebotExtraTitle { 
    font-size: 20px;
    font-weight: 600;
    grid-area: angebotExtraTitle;
    align-self: center;
}

/* Objekt Detail Grid */ 

.angebotObjectDetailGridContainer { 
    grid-area: objectDetailGridContainer;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 0px;
    grid-template-areas: 
    "angebotInputTitle"
    "objectDetailGrid1"
    "objectDetailGrid2"
    "objectDetailGrid3"
    "objectDetailGrid4"
    "objectDetailGrid5"
    "objectDetailGrid6"
    "objectDetailGrid7"
    "objectDetailGrid8"
    "objectDetailGrid9"
    "objectDetailGrid10"
    "objectDetailGrid11"
    ;
}

.angebotObjectDetailGrid:first-of-type { 
    margin-top: 5px;
}

.angebotObjectDetailGrid { 
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 5px;
    margin-bottom: 5px;
    grid-template-areas:
    "objectType objectLenght objectWidth objectCount"
    ;
}

.angebotObjectDetailGrid select { 
    border-radius: 10px;
    border: 1px solid gray;
    background-color: white;
    font-size: 16px;
    color: gray;
}

.angebotDatenschutzContainer { 
    display: flex;
    align-items: center;
    gap: 5px;
}

.angebotDatenschutzContainer p { 
    font-size: 16px;
    font-weight: 500;
}

.angebotObjectMessageGrid { 
    grid-area: angebotObjectMessageGrid;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 5px;
    grid-template-areas: 
    "angebotInputTitle angebotInputTitle"
    "objectDetailMessage objectDetailMessage"
    ;
}

.angebotSendMailButton { 
    grid-area: sendMailButton;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    width: 100%;
    height: 40px;
    background-color: orange;
    border: none;
    text-decoration: none;
    color: white;
    max-width: 300px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
}

@media screen and (max-width: 800px) {
    .angebotObjectDetailGrid {
        grid-template-columns: 50% 50%;
        grid-template-areas:
            "objectType objectLenght"
            "objectWidth objectCount"
        ;
    }

    .angebotObjectGrid {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
        "angebotInputTitle angebotInputTitle"
        "objectAdress objectAdress" 
        "objectZipCode angebotExtraTitle"
    }

    #angebotInputTitleObject {
        grid-column: span 2;
    }
}