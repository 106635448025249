.linkButton { 
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    width: 100%;
    height: 40px;
    background-color: orange;
    border: none;
    text-decoration: none;
    color: white;
    max-width: 300px;
    padding-left: 10px;
    padding-right: 10px;
}