
/* Container */
.AWDImageLinkCardContainer { 
    width: 100%;
    border-radius: 24px;
    overflow: hidden;
    box-shadow: 5px 5px 5px lightgrey;
    background-color: white;
}

/* Title Container */
.imageLinkCardTitleContainer { 
    height: 40px;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.02); 
}

/* Title Text */
.imageLinkCardTitle { 
    font-weight: bold;
    font-size: 30px;
    padding-left: 10px;
    text-transform: uppercase;
}

/* Image Container */
.imageLinkCardImageContainer { 
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
}

/* Image */
.imageLinkCardImage { 
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Content Contenainer */
.imageLinkCardContentContainer {
    background-color: rgb(84, 171, 233); 
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding: 10px;
}

/* Subtitle */
.imageLinkCardSubTitle { 
    color: white;
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
    text-align: center;
}

/* Extern Link */

.imageLinkCardExternLinkButton { 
    display: flex;
    font-family: 'Avenir Next';
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 16;
    font-weight: bold;
    color: white;
    border: none;
    height: 40px;
    width: 300px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: orange;
    border-radius: 5px;
}

/*  
style={{, alignSelf: 'center', fontSize: 16, color:'#333333', fontFamily: 'Avenir Next'}}>
*/