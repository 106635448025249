.scrollviewContainer {
    overflow-x: scroll;
    width: 100%;
    grid-column: 2 span;
}

.scrollview {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    white-space: nowrap;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
}

.image-container {
    flex: 0 0 auto;
    width: 500px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    object-fit: cover;
    border-radius: 20px;
}

#scrollViewImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width: 1000px) {
    .image-container {
        width: 350px;
        height: 250px;
    }
}