.AWDTextCardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff; 
    padding: 10px;
    gap: 5px;
    border: none;
    border-radius: 20px;
    box-shadow: 5px 5px 5px lightgrey;
}

.textCardTitle {
    font-size: 30px;
    font-weight: bold;
    color: #333333;
}

.textCardSubTitle {
    font-size: 20px;
    font-weight: bold;
    color: rgb(84, 171, 233);
    text-align: center;
}

.textCardDescription {
    color: #333333;
    text-align: center;
}

.textCardBulletPointContainer {
    align-self: flex-start;
    margin-left: 40px;

}

.textCardBulletPoint {
    display: flex;
    font-weight: 600;
    gap: 5px;
}

.textCardBulletPointText {
    margin-bottom: 3px;
}

@media screen and (max-width: 600px) {
    .textCardDescription {
        text-align: left;
    }
}