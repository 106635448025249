.footerContainer {
    background-color: lightgray;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
        "footerGrid footerGrid"
    ;
}

.spaceDiv {
    grid-area: spaceDiv;
}

.footerGrid {
    grid-area: footerGrid;
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    margin-left: 500px;
    margin-right: 20px;
    gap: 0px 10px;
}

.footerButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    border: none;
    background-color: rgba(255, 255, 255, 0);
    text-decoration: none;
    color: white;
    text-align: left;
    color: #333333;
}

@media screen and (max-width: 1370px) {
    .footerGrid {
        margin-left: 300px;
    }
}

@media screen and (max-width: 1000px) {
    .footerGrid {
        margin-left: 20px;
    }
}