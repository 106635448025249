
.fuhrparkVariantContainer { 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    background-color: white;
    padding: 10px;
    gap: 10px;
    box-shadow: 5px 5px 5px lightgrey;
    border-radius: 20px;
}

.fuhrparkVariantIconContainer { 
    height: 80px;
    width: 80px;
    background-color: lightgray;
    border-radius: 40px;
}

.fuhrparkVaraintTitle { 
    font-size: 24px;
    font-weight: 600;
}

.fuhrparkVaraintDescription { 
    text-align: center;
}