.AWDWinterdienstZipCodeCardContainer { 
    width: 100%;
    border-radius: 24px;
    overflow: hidden;
    box-shadow: 5px 5px 5px lightgrey;
    background-color: white;
    grid-area: wdZipCodeCard;
}

/* Titel Container*/
.winterdienstZipCodeCardTitleContainer { 
    min-height: 40px;
    display: flex;
    align-items: center;
    background-color: #ffffff; 
}

/* Titel */
.winterdienstZipCodeCardTitle { 
    font-weight: bold;
    font-size: 30px;
    padding-left: 10px;
    text-transform: uppercase;
}

/* Image Container*/
.winterdienstZipCodeCardImageContainer { 
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
}

.winterdienstZipCodeCardImage { 
    width: 100%;
    max-width: 700px;
    height: 100%;
    object-fit: cover;
}

/* ZipCode Content Container*/
.winterdienstZipCodeCardContentContainer {
    background-color: rgb(84, 171, 233); 
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding: 10px;
    height: 100%;
}

/* Subtitle */
.winterdienstZipCodeCardSubTitle { 
    color: white;
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
}

/* ZipCode Action Container*/
.winterdienstZipCodeCardActionContainer { 
    display: flex;
    align-items: center;
    
}

/* ZipCode Input Container*/
.winterdienstZipCodeCardInputContainer { 
    display: flex;
    align-items: center;
    gap: 5px;
}

.winterdienstInputActionButton {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    width: 80px;
    height: 40px;
    background-color: orange;
    border: none;
    border-radius: 5px;
}

.text-input-container {
    position: relative;
    border-radius: 5px;
    border: 1px solid gray;
    background-color: white;
}

.text-input-clear-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    border: none;
    font-size: 20px;
    font-weight: 600;
    color: gray;
    background-color: white;
}

.text-input {
    height: 40px;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    font-size: 16px;
    padding-left: 20px;
}

.text-input::placeholder {
    color: gray;
}

.text-input:focus {
    outline: none;
}

.text-input-line {
    position: absolute;
    bottom: 10px;
    left: 20px;
    right: 20px;
    border-bottom: 1px solid gray;
}