
.fuhrparkCardGrid {
    margin: 30px 20px;
    display: grid;
    max-width: 1400px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 20px;
    grid-template-areas: 
    "fuhrparkVehicleCard  fuhrparkVehicleCard fuhrparkDetailImage"
    "fuhrparkPickupImage1 fuhrparkFeatureCard fuhrparkFeatureCard"
    "fuhrparkOverviewImage fuhrparkFeatureCard fuhrparkFeatureCard"
    "fuhrparkSnowImage fuhrparkFeatureCard fuhrparkFeatureCard"
    "fuhrparkVehicleImage fuhrparkFeatureCard fuhrparkFeatureCard"
    "variantTitle variantTitle variantTitle"
    "varaint1 varaint2 varaint3"
    ;
}

@media screen and (max-width: 1370px) {
    .fuhrparkCardGrid {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "fuhrparkVehicleCard fuhrparkDetailImage"
            "fuhrparkPickupImage1 fuhrparkOverviewImage"
            "fuhrparkFeatureCard fuhrparkFeatureCard"
            "fuhrparkSnowImage fuhrparkVehicleImage"
            "variantTitle variantTitle"
            "varaint1 varaint2"
            "varaint3 varaint3"
        ;
        }
}

@media screen and (max-width: 800px) {
    .fuhrparkCardGrid {
    grid-template-columns: 1fr;
    grid-template-areas: 
     "fuhrparkVehicleCard"
     "fuhrparkDetailImage"
     "fuhrparkPickupImage1"
     "fuhrparkFeatureCard" 
     "fuhrparkOverviewImage"
     "fuhrparkSnowImage"
     "fuhrparkVehicleImage"
     "variantTitle"
     "varaint1"
     "varaint2"
     "varaint3"
    ;
    }

    #fuhrparkDetailImage {
        max-height: 200px;
    }
}

.fuhrparkVariantTitle { 
    grid-area: variantTitle;
    margin-top: 20px;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color:  rgb(84, 171, 233);
}

#fuhrparkPickupImage1   {
    max-height: 250px;
}

#fuhrparkOverviewImage{ 
    max-height: 250px;
}

#fuhrparkSnowImage { 
    max-height: 250px; 
}