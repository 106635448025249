.rechtLinkCardContainer { 
    background-color: white;
    box-shadow: 5px 5px 5px lightgrey;
    border-radius: 20px;
    max-width: 700px;
    width: 100%;
    justify-self: center;
}

.rechtLinkCardContent { 
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.rechtLinkCardTitle { 
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}

.rechtLinkCardDescription { 
    text-align: center;
}

