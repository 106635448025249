
.homePageCardTopics { 
    margin-top: 30px;
    display: grid;
    gap: 20px;
    justify-items: center;
    margin: 24px;
}

@media screen and (max-width: 799px) {
    #homeTopicCardNavigationButton { 
        width: 100%;
    }
}

@media screen and (min-width: 1050px) { 
    .pageNavbarRight div h4 { 
        display: block;
    }
}

@media screen and (min-width: 1199px) {
    .homePageCardTopics {
        grid-template-columns: repeat(2, minmax(300px, 1fr)); 
        max-width: 1400px;
        margin: 24px auto;
        padding: 0 20px;
    }

    .homePageCardTopics > :last-child {
        grid-column: span 2; 
        justify-self: center;
    }
}