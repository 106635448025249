.baumarbeitenCardGrid { 
    margin: 30px 20px;
    display: grid;
    max-width: 1400px;
    height: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 20px;
    grid-template-areas: 
     "baumInfo baumInfoImage"
     "baumFeatureImage baumFeature"
     "baumRecht baumRecht"
    ;
}

@media screen and (max-width: 1000px) {
    .baumarbeitenCardGrid {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-gap: 10px;
        grid-template-areas:
            "baumInfo" 
            "baumInfoImage"
            "baumFeature"
            "baumFeatureImage" 
            "baumRecht"
        ;
    }

    #baumarbeitenDetailImage { 
        max-height: 300px;
    }

    #baumarbeitenOldImage { 
        max-height: 300px;
    }
}