
.winterdienstRefContentGrid { 
    margin-top: 10px;
    display: grid;
    margin-top: 50px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 50px;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: 
     "rechtTitle rechtTitle"
     "rechtSubTitle rechtSubTitle"
     "winterdienstRefGrid winterdienstRefGrid"
    ;
}

.winterdienstRefGrid { 
    grid-area: winterdienstRefGrid;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 10px;
    margin: 0px 50px;
    grid-template-areas: 
    "ref1 ref2 ref3"
    "ref4 ref5 ref6"
    "ref7 ref8 ref9"
    ;
}

@media screen and (max-width: 1000px) {
    .contentGrid {
        grid-template-columns: 1fr;
        grid-template-areas:
            "rechtTitle"
            "rechtSubTitle"
            "winterdienstRefGrid"
        ;
    }

    .winterdienstRefGrid { 
        margin: 0px 20px;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 
        "ref1 ref2"
        "ref3 ref4"
        "ref5 ref6"
        "ref7 ref8"
        "ref9 ref9"
        ;
    }
}

@media screen and (max-width: 600px) {
    .winterdienstRefGrid { 
        margin: 0px;
        grid-template-columns: 1fr;
        grid-template-areas: 
            "ref1"
            "ref2"
            "ref3"
            "ref4"
            "ref5"
            "ref6"
            "ref7"
            "ref8"
            "ref9"
        ;
    }
}

.winterdienstRefTitle { 
    font-weight: bold;
    font-size: 40px;
    grid-area: rechtTitle;
}

.winterdienstRefSubTitle {
    font-weight: 600;
    font-size: 25px;
    color: gray;
    grid-area: rechtSubTitle;
}