.landschaftsbauCardGrid { 
    margin: 30px 20px;
    display: grid;
    max-width: 1400px;
    height: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 20px;
    grid-template-areas: 
     "landschaftInfo landschaftInfoImage"
     "landschaftScrollView1 landschaftScrollView1"
     "wegebau wegebauImage"
     "zahnbauImage zaunbau"
     "holzbau holzbauImage"
    ;
}

@media screen and (max-width: 1000px) {
    .landschaftsbauCardGrid {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-gap: 10px;
        grid-template-areas:
            "landschaftInfo" 
            "landschaftInfoImage"
            "landschaftScrollView1"
            "wegebau" 
            "wegebauImage"
            "zaunbau"
            "zahnbauImage"
            "holzbau"
            "holzbauImage"
        ;
    }
}

#landschaftbauInfoImage { 
    height: 350px;
}

#wegebauInfoImage { 
    height: 350px;
}

#zaunbauInfoImage { 
    height: 350px;
}

#holzbauInfoImage { 
    height: 350px;
}

@media screen and (max-width: 1000px) {
    #landschaftbauInfoImage { 
        height: 250px;
    }

    #wegebauInfoImage { 
        height: 250px;
    }
    
    #zaunbauInfoImage { 
        height: 250px;
    }
    
    #holzbauInfoImage { 
        height: 250px;
    }
}