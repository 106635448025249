
.AWDImageCardContainer {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
}

.imageCardImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}