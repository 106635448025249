
.AWDFuhrparkFeatureCardContainer { 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 10px;
    gap: 10px;
    border: none;
    border-radius: 20px;
    box-shadow: 5px 5px 5px lightgrey;
}

.fuhrparkFeatureCardTitle { 
    font-size: 30px;
    font-weight: bold;
    color: rgb(64, 64, 64);
    text-align: center;
}

.fuhrparkFeatureCardSubTitle { 
    font-size: 20px;
    font-weight: bold;
    color: gray;
}

.fuhrparkFeatureCardDescription { 
    text-align: center;
}

.fuhrparkFeatureCardBulletPointContainer { 
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.fuhrParkFeatureBulletPointTextContainer { 
    display: flex;
}

.fuhrparkFeatureCardBulletPointTitle { 
    font-weight: bold;
    color: gray;
    min-width: 250px;
}

.fuhrparkFeatureEndText { 
    padding: 0px 40px;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    margin-top: 20px;
}

#fuhrparkFeatureImageSkizze { 
    max-height: 400px;
}

@media screen and (max-width: 800px) {
    .fuhrParkFeatureBulletPointTextContainer {
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 3px;
    }

    #fuhrparkDetailImage {
        max-height: 200px;
    }
}