.angebotTextInputContainer {
    position: relative;
    border-radius: 10px;
    border: 1px solid gray;
    background-color: white;
}

.angebotClearTextButton {
    position: absolute;
    bottom: 5px;
    right: 10px;
    border: none;
    font-size: 20px;
    font-weight: 600;
    color: gray;
    background-color: rgba(0, 0, 0, 0);
}

.angebotTextInput {
    height: 40px;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    font-size: 16px;
    width: 100%;
    padding-left: 20px;
}

.angebotTextInput:focus {
    outline: none; 
}

.angebotTextInput::placeholder {
    color: gray;
}

.text-input:focus {
    outline: none;
}

.text-input-line {
    position: absolute;
    bottom: 7px;
    left: 20px;
    right: 20px;
    border-bottom: 1px solid gray;
}