
.aboutUsImageGrid { 
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;
    grid-template-areas:
        "gridImage1 gridImage2"
    ;
}

@media screen and (max-width: 800px) {
    .aboutUsImageGrid {
        grid-template-columns: 1fr;
        grid-template-areas:
            "gridImage1"
            "gridImage2"
        ;
    }
}

#aboutUsImage { 
    min-height: 200px;
}