
/* Teaser Container */
.teaserContainer { 
    display: flex;
}

/* Teaser Image Container */
.teaserImageContainer { 
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 600px;
    overflow: hidden;
    z-index: -1;
    position: fixed;
    background-color: gray;
}

#teaserSmallImage { 
    height: 100%;
}

/* Teaser Image */
.teaserImage { 
    width: 100%;
    height: auto;
    object-fit: cover;
}

@media screen and (max-width: 1390px) {
    .teaserImage { 
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

/* Teaser Title Container */
.teaserTitleContainer { 
    color: #333333; 
    margin-top: 12vw;
    max-width: 75vw;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 0px 15px 15px 0px;
}

/* Teaser Title Text */
.teaserTitleText { 
    font-size: 50px;
    font-weight: bold;
    margin-left: 20px;
    margin-right: 40px;
    margin-top: 10px;
    margin-bottom: 5px;
    line-height: 50px;
}

/* Teaser Title Container */
.teaserSubTitleContainer { 
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
    margin-bottom: 10px;
}

/* Teaser SubTitle Text */
.teaserSubTitleText { 
    font-size: 20px;
    font-weight: bold;
    margin-right: 5px;
    color: rgb(84, 171, 233);
}

@media screen and (max-width: 799px) {
    .teaserTitleText { 
        font-size: 30px;
        line-height: 35px;
    }

    .teaserSubTitleText { 
        font-size: 15px;
    }
}